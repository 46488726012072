<template>
  <div class="allclassmsg">
    <navigation :msg="msg"></navigation>

    <div class="divimgclass">
      <img src="../../assets/makemoney.png" alt="" />
    </div>
    <div class="fenxiangclass">
      <img src="../../assets/fenxiang2.png" />
      <div><span>分享邀请，</span><span>200到账</span></div>
    </div>
    <div class="topmsgclass">
      <img src="../../assets/tu1.png" />
      <div class="allme1">
        <div class="me1">
          <div></div>
          <div>点击底部菜单栏“推广邀请”</div>
        </div>
        <div class="me2">
          <div><div class="dian"></div></div>
          <div>
            <div>生成您的专属海报，发送给好友</div>
            <div>每位好友完成新用户注册，您的</div>
            <div>余额瞬间+20元，好友完成企业认</div>
            <div>证，您的余额再加180元。</div>
          </div>
        </div>
      </div>
    </div>

    <div class="fenxiangclass fxc">
      <img src="../../assets/tu2.png" />
      <div><span>没用简历“变废为宝”，</span><span>一份20元</span></div>
    </div>
    <div class="topmsgclass">
      <img src="../../assets/tu3.png" />
      <div class="allme1">
        <div class="me1">
          <div></div>
          <div>登录萝卜猎手PC端</div>
        </div>
        <div class="me1">
          <div></div>
          <div>点击“简历公海”--“上传简历”</div>
        </div>
        <div class="me2">
          <div><div class="dian"></div></div>
          <div>
            <div>按指引完成简历上传。每次简历</div>
            <div>上传成功，余额瞬间+20元！</div>
          </div>
        </div>
      </div>
    </div>
    <sharepage></sharepage>
  </div>
</template>

<script>
import sharepage from "../../views/sharepage/index";
export default {
  data() {
    return {
      msg: "轻松赚钱",
    };
  },
  components:{
    sharepage
  },
  created(){
    this.tosharemeth();
  },
  methods: {
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "轻松赚钱",
        sharedesc: "简历变废为宝",
        isshare: "1",
        share_phone:localStorage.getItem("phone")
      });
      localStorage.setItem("shareobj", obj);
    },
    onClickLeft() {
      Toast("返回");
    },
  },
};
</script>

<style scoped>
.allclassmsg{
  background-color: #ffffff;
  padding-bottom: .3rem;
  min-height: 100%;
}
.fxc{
  margin-top: 1.25rem;
}
.allme1 {
  position: absolute;
  top: -0.3rem;
  right: 0;
}
.me2 {
  display: flex;
  font-size: 0.28rem;
  line-height: 0.6rem;
  font-weight: bold;
}
.me2 > div:first-child {
  padding-top: 0.2rem;
}
.me2 .dian {
  width: 0.14rem;
  height: 0.14rem;
  background-color: #f55814;
  border-radius: 50%;
  line-height: 0.4rem;
  margin-right: 0.23rem;
}
.me1 {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  line-height: 0.6rem;
  font-weight: bold;
}
.me1 > div:first-child {
  width: 0.14rem;
  height: 0.14rem;
  background-color: #f55814;
  border-radius: 50%;
  margin-right: 0.23rem;
}
.topmsgclass {
  margin: 0.5rem;
  margin-top: 0.68rem;
  position: relative;
}
.topmsgclass img {
  width: 2.35rem;
  height: 2.35rem;
}
.divimgclass img {
  width: 100%;
}
.divimgclass {
  margin: 0.2rem;
}
.fenxiangclass > span:nth-child(2) {
  color: #f55814;
}
.fenxiangclass img {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 0.18rem;
}
.fenxiangclass {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 0.36rem;
  line-height: 0.6rem;
  font-weight: bold;
}
.head {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.2rem;
}
.title {
  display: flex;
  padding: 0.2rem 0;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.6rem;
  background: url("../../assets/yuan.png") no-repeat center center;
  background-size: 40%;
}
.title img {
  margin-left: 0.5rem;
  margin-right: 0.18rem;
  width: 0.7rem;
  height: 0.7rem;
}
.title div span {
  color: #f55814;
}
.mini {
  background: url("../../assets/tu1.png") no-repeat 0.38rem 0.3rem;
  background-size: 2.35rem;
}
.mini li {
  transform: translateY(-0.4rem);
  margin-left: 2.52rem;
  width: 4.5rem;
  display: inline-block;
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.5rem;
  margin-bottom: 0.3rem;
}
.cc {
  background: url("../../assets/tu3.png") no-repeat 0.38rem 0.3rem;
  background-size: 2.35rem;
}
.mini span {
  /* transform: translate(0.1rem, -0.05rem); */
  display: inline-block;
  margin-right: 0.2rem;
  width: 0.14rem;
  height: 0.14rem;
  background: #f55814;
  border-radius: 50%;
}
/* 此处有bug强制移动 */
.sy {
  position: relative;
  top: -0.4rem;
}
</style>